<template>
  <div>
    <v-row
      v-if="requirements && requirements.length && !user.position?.length"
      data-cy="requirements"
    >
      <v-col
        v-if="hasRole(['ROTATING_STAFF']) && goalsProgress.length > 0"
        cols="12"
      >
        <charts-rings
          :chart-data="goalsProgress"
          data-cy="chartRings"
        />
      </v-col>
      <v-col
        v-for="requirement in requirementsWithProgress"
        :key="requirement"
        data-cy="requirementsCards"
        cols="12"
        :md="list ? 12 : 6"
        :class="{
          'pb-0': dense,
        }"
      >
        <schedule-goals-collapsible-rotation-card
          :requirement="requirement"
          :open-sidebar="openSidebar"
          :expanded="expanded"
          :list="list"
        />
      </v-col>
      <v-col
        cols="12"
        :md="list ? 12 : 6"
      >
        <v-card
          class="bg-grey-lighten-3 add-link d-flex flex-column"
          variant="flat"
          min-height="75px"
          @click="toggleExpandMoreRotation"
        >
          <v-row no-gutters>
            <v-col class="d-flex align-center">
              <v-progress-circular
                v-if="!expandedMoreRotation"
                :model-value="otherHistoryMonths > 0 ? 100 : 0"
                class="ml-4"
                color="primary"
                :size="50"
              >
                {{ formatNumber(otherHistoryMonths) }}
              </v-progress-circular>
              <v-card-title class="speciality-title d-flex align-center title-height">
                weitere Rotationen
              </v-card-title>
            </v-col>
            <v-col
              cols="auto"
              class="d-flex align-center justify-center pr-3"
            >
              <v-icon>{{ expandedMoreRotation ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-col>
          </v-row>
          <v-expand-transition>
            <div v-show="expandedMoreRotation">
              <v-divider></v-divider>
              <v-card-text class="py-0">
                <span
                  class="font-weight-bold"
                  :class="{ 'text-h6': list, 'text-h2': !list }"
                >
                  {{ formatNumber(otherHistoryMonths) }} Monate </span
                ><br />
                <div class="d-flex justify-space-between align-center mt-4">
                  <v-btn
                    rounded="xl"
                    color="primary"
                    class="mb-3"
                    data-cy="openAllHistorySidebar"
                    @click.stop="openSidebar(allSpecialities, true, false)"
                  >
                    Bearbeiten
                    <template #append>
                      <v-icon>mdi-arrow-right</v-icon>
                    </template>
                  </v-btn>
                  <v-card-title
                    v-if="otherHistoryActive"
                    class="speciality-title"
                  >
                    aktuelle Rotation

                    <v-icon color="success">mdi-radiobox-marked</v-icon>
                  </v-card-title>
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        :class="$vuetify.display.mdAndDown ? 'text-center' : 'text-right'"
      >
        <v-btn
          class="px-10"
          rounded
          variant="tonal"
          color="primary"
          append-icon="mdi-arrow-right"
          @click="openSidebar(null, null, null)"
        >
          Alle Rotationen ansehen
        </v-btn>
      </v-col>
    </v-row>
    <schedule-history-cards
      v-else-if="requirements"
      :user-id="userId"
      :teaser="teaser"
    ></schedule-history-cards>
    <app-side-bar
      v-if="sidebarOpen"
      v-model="sidebarOpen"
      title="Rotationen ansehen / bearbeiten"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <schedule-history-cards
              :user-id="userId"
              :include-specialities="sidebar.includeSpecialities"
              :exclude-specialities="sidebar.excludeSpecialities"
              :external="sidebar.external"
              @edit="loadData"
            ></schedule-history-cards>
          </v-col>
        </v-row>
      </v-container>
    </app-side-bar>
  </div>
</template>
<script>
import { min, addMonths, addDays, differenceInDays, differenceInMonths } from 'date-fns'
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    list: {
      type: Boolean,
    },
    teaser: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    progressMonths: {
      type: Number,
      default: 0,
    },
    totalMonths: {
      type: Number,
      default: 0,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:totalMonths', 'update:progressMonths'],
  data() {
    return {
      user: null,
      requirements: null,
      history: [],
      goalsProgress: [],
      sidebarOpen: false,
      sidebar: {
        includeSpecialities: [],
        excludeSpecialities: [],
        external: false,
      },
      expandedMoreRotation: this.expanded,
    }
  },
  computed: {
    allSpecialities() {
      const allSpecialities = this.requirements.map((req) => req.specialities).flat()
      return allSpecialities.map((s) => s.speciality_id.id).join(',')
    },
    requirementsWithProgress() {
      if (this.requirements) {
        const requirements = this.requirements.map((requirement) => {
          const history = this.history.filter((entry) =>
            entry.specialities.some((spec) =>
              requirement.specialities
                .map((s) => s.speciality_id.id)
                .includes(spec.speciality_id.id)
            )
          )
          let requirementMonths = 0
          requirement.specialities = requirement.specialities.map((s) => {
            const speciality_id = s.speciality_id
            const history = this.history.filter((entry) =>
              entry.specialities.some((spec) => spec.speciality_id.id === s.speciality_id.id)
            )
            const specialityMonths = history.map((e) => e.months).reduce((a, b) => a + b, 0)
            speciality_id.months = specialityMonths
            requirementMonths = requirementMonths + specialityMonths
            return { speciality_id }
          })
          const months = history.map((e) => e.months).reduce((a, b) => a + b, 0)
          let isHome = false
          if (
            this.user?.facilities?.length &&
            this.user.facilities[0].facility_id?.specialities?.length
          ) {
            isHome = requirement.specialities.some((spec) =>
              this.user.facilities[0].facility_id.specialities
                .map((spec) => spec.speciality_id)
                .includes(spec.speciality_id.id)
            )
          }
          const now = new Date()
          return {
            ...requirement,
            active: history.some((entry) => this.isInTime(entry, now, now)),
            finished:
              requirement.minimum_months > 0 ? months >= requirement.minimum_months : months > 0,
            months: requirementMonths,
            isHome,
          }
        })
        requirements.sort((a, b) => {
          const targetSpecialityCompare = b.isHome - a.isHome
          const lengthCompare = a.specialities.length - b.specialities.length
          return targetSpecialityCompare || lengthCompare
        })
        return requirements
      }
      return []
    },
    otherHistory() {
      return this.history.filter(
        (entry) =>
          !this.requirements?.length ||
          !entry.specialities.some((spec) =>
            this.requirements
              .map((requirement) => requirement.specialities.map((s) => s.speciality_id.id))
              .flat()
              .includes(spec.speciality_id.id)
          )
      )
    },
    otherHistoryMonths() {
      return this.otherHistory.map((e) => e.months).reduce((a, b) => a + b, 0)
    },
    otherHistoryActive() {
      const now = new Date()
      return this.otherHistory.some((entry) => this.isInTime(entry, now, now))
    },
  },
  watch: {
    sidebarOpen(val) {
      if (!val) {
        this.sidebar = {
          includeSpecialities: [],
          excludeSpecialities: [],
          external: false,
        }
      }
    },
  },
  async mounted() {
    this.user = await this.$cms.request(
      this.$readUser(this.userId, {
        fields: [
          'id',
          'position',
          'facilities.id',
          'facilities.facility_id.id',
          'facilities.facility_id.specialities.id',
          'facilities.facility_id.specialities.speciality_id',
        ],
      })
    )
    this.loadData()
  },
  methods: {
    async loadData() {
      const endNodes = await this.$cms.request(
        this.$readItems('schedule_flow_node', {
          filter: {
            _and: [
              {
                schedule_flow: {
                  facility: {
                    users: {
                      directus_users_id: {
                        _eq: this.userId,
                      },
                    },
                  },
                },
              },
              {
                type: {
                  _eq: 'end',
                },
              },
            ],
          },
          fields: ['id'],
        })
      )
      if (endNodes.length) {
        this.$cms
          .request(
            this.$readItems('schedule_requirements', {
              filter: {
                target: {
                  'item:schedule_flow_node': {
                    id: {
                      _eq: endNodes[0].id,
                    },
                  },
                },
              },
              fields: [
                'id',
                'minimum_months',
                'specialities.id',
                'specialities.speciality_id.id',
                'specialities.speciality_id.name',
              ],
            })
          )
          .then((response) => {
            this.requirements = response
            const totalMonths = this.requirements
              .map((r) => r.minimum_months)
              .reduce((a, b) => a + b, 0)
            this.$emit('update:totalMonths', Math.round(totalMonths))
          })
        await this.$cms
          .request(
            this.$readItems('schedule_history', {
              filter: {
                _and: [
                  {
                    user: {
                      _eq: this.userId,
                    },
                  },
                  {
                    overwritten_by: {
                      _null: true,
                    },
                  },
                  {
                    status: {
                      _nin: ['rejected', 'review'],
                    },
                  },
                  {
                    start: {
                      _lte: new Date().toISOString().substring(0, 10),
                    },
                  },
                ],
              },
              fields: [
                'id',
                'status',
                'start',
                'type',
                'specialities.id',
                'specialities.speciality_id.id',
                'specialities.speciality_id.name',
                'facility.id',
                'facility.facility_id.id',
                'facility.facility_id.name',
                'end',
                'percentage',
                'extend_months',
                'request',
                'unit.id',
                'unit.short_name',
                'facility.id',
                'facility.name',
                'rotation_type',
                'schedule_section.id',
                'schedule_section.phase',
                'schedule_section.priority',
                'overwrite',
              ],
              sort: ['-start'],
              limit: -1,
            })
          )
          .then((response) => {
            this.history = response.map((entry) => {
              let start = this.parseDate(entry.start)
              let end = this.parseDate(entry.end)

              // only calculate progress until today
              end = min([end, new Date()])

              const months = differenceInMonths(addDays(end, 1), start)
              start = addMonths(start, months)
              const days = Math.max(differenceInDays(end, start), 0)

              const factor = entry.percentage ? entry.percentage / 100 : 1
              entry.months = (months + days / 30) * factor
              entry.months =
                entry.months % 1 > 0.89 || entry.months % 1 < 0.11
                  ? Math.round(entry.months)
                  : entry.months
              return entry
            })
            nextTick(() => {
              const progressMonths = this.requirementsWithProgress
                .map((r) => r.months)
                .reduce((a, b) => a + b, 0)
              this.$emit('update:progressMonths', progressMonths + this.otherHistoryMonths)
              this.getGoalsProgress(this.requirementsWithProgress)
            })
          })
      } else {
        this.requirements = []
      }
    },
    openSidebar(specialities, exclude, external) {
      if (exclude) {
        this.sidebar.excludeSpecialities = specialities
      } else {
        this.sidebar.includeSpecialities = specialities
      }
      this.sidebar.external = external
      this.sidebarOpen = true
    },
    getGoalsProgress(requirements) {
      this.goalsProgress = requirements
        .filter((requirement) => requirement.minimum_months > 0)
        .map((requirement) => {
          const value = Math.round(requirement.specialities[0].speciality_id.months)
          const goal = requirement.minimum_months
          const perc = Math.min(value / goal, 1)
          return {
            name: requirement.specialities[0].speciality_id.name,
            value: value,
            goal: goal,
            perc: perc,
            unit: 'Monate',
          }
        })
    },
    toggleExpandMoreRotation() {
      this.expandedMoreRotation = !this.expandedMoreRotation
    },
  },
}
</script>
<style>
.speciality-title {
  line-height: 150% !important;
}
</style>
