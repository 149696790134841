<template>
  <v-card
    :class="{
      'bg-success text-white': requirement.finished && requirement.minimum_months > 0,
      'bg-white': requirement.active && !requirement.finished,
    }"
    class="bg-grey-lighten-3 d-flex flex-column"
    color="primary"
    variant="outlined"
    min-height="75px"
    data-cy="requirementCard"
    @click="toggleExpand"
  >
    <v-row
      no-gutters
      class="flex-1-1-100"
    >
      <v-col
        cols="11"
        class="d-flex align-center"
      >
        <v-card-title class="speciality-title d-flex align-center title-height">
          <v-slide-x-transition class="custom-slide-x-transition">
            <v-progress-circular
              v-if="!isExpanded"
              :model-value="progressPercentage"
              :color="requirement.finished && requirement.minimum_months ? 'white' : 'primary'"
              class="mr-3"
              :class="{
                'progress-visible': !isExpanded && requirement.minimum_months,
              }"
              :size="50"
            >
              {{ formatNumber(requirement.months) }}
            </v-progress-circular>
          </v-slide-x-transition>
          <v-slide-x-transition
            v-if="requirement.specialities.length === 1"
            class="custom-slide-x-transition"
          >
            <span
              v-for="(spec, i) in requirement.specialities"
              :key="spec.id"
              class="text-truncate"
              :style="{
                maxWidth: isExpanded ? '100%' : '400px',
              }"
            >
              {{ spec.speciality_id.name }}
              <v-icon
                v-if="requirement.specialities.length > 1 && spec.speciality_id.months > 0"
                class="ml-1"
                size="small"
              >
                mdi-check-circle-outline
              </v-icon>
            </span>
          </v-slide-x-transition>
          <div
            v-if="requirement.specialities.length > 1"
            class="text-truncate"
            :style="{
              maxWidth: isExpanded ? '100%' : '400px',
            }"
          >
            <span
              v-for="(spec, i) in requirement.specialities"
              :key="spec.id"
            >
              {{ spec.speciality_id.name }}
              <v-icon
                v-if="spec.speciality_id.months > 0"
                class="ml-1"
                size="small"
              >
                mdi-check-circle-outline
              </v-icon>
              <br v-if="i !== requirement.specialities.length - 1" />
            </span>
          </div>
          <v-chip
            v-if="requirement.isHome && requirement.specialities.length === 1"
            class="ml-2"
            size="small"
            :color="requirement.finished ? 'white' : 'primary'"
            variant="tonal"
          >
            Stammklinik
          </v-chip>
        </v-card-title>
      </v-col>
      <v-col
        cols="1"
        class="d-flex align-center justify-center pr-3"
      >
        <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-col>
    </v-row>

    <v-expand-transition>
      <div v-show="isExpanded">
        <v-divider></v-divider>
        <v-card-text>
          <span
            class="font-weight-bold"
            :class="{
              'text-white': requirement.finished && requirement.minimum_months > 0,
              'text-h6': list,
              'text-h2': !list,
            }"
          >
            {{ formatNumber(requirement.months) }}
            <span v-if="requirement.minimum_months && requirement.minimum_months > 0"
              >/{{ requirement.minimum_months }}</span
            >
            Monate
          </span>
          <br />
          <v-progress-linear
            v-if="requirement.minimum_months && requirement.minimum_months > 0"
            :model-value="progressPercentage"
            :class="{ 'mt-1': list, 'mt-3': !list }"
          ></v-progress-linear>
          <div class="d-flex justify-space-between align-center mt-4">
            <v-btn
              :color="requirement.finished ? null : 'primary'"
              rounded="xl"
              data-cy="openHistorySidebar"
              @click.stop="
                openSidebar(
                  requirement.specialities.map((s) => s.speciality_id.id),
                  false,
                  !requirement.isHome
                )
              "
            >
              Bearbeiten
              <template #append>
                <v-icon>mdi-arrow-right</v-icon>
              </template>
            </v-btn>
            <v-card-title
              v-if="(requirement.active || requirement.finished) && requirement.minimum_months > 0"
              class="speciality-title pa-0"
            >
              {{ getStatusText(requirement) }}
              <v-icon
                v-if="requirement.active"
                :color="requirement.finished ? 'white' : 'success'"
                >mdi-radiobox-marked</v-icon
              >
              <v-icon
                v-else-if="requirement.finished"
                color="white"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-radiobox-blank</v-icon>
            </v-card-title>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    requirement: {
      type: Object,
      required: true,
    },
    openSidebar: {
      type: Function,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
    },
  },
  data() {
    return {
      isExpanded: this.expanded,
    }
  },
  computed: {
    progressPercentage() {
      return this.requirement.minimum_months
        ? (this.requirement.months / this.requirement.minimum_months) * 100
        : 100
    },
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    },
    getStatusText(requirement) {
      if (requirement.active) {
        return 'aktuelle Rotation'
      } else if (requirement.finished) {
        return 'abgeschlossen'
      } else {
        return 'inaktiv'
      }
    },
  },
}
</script>
<style>
.title-height {
  min-height: 75px;
}

.speciality-title .progress-visible ~ span {
  margin-left: 5px;
}

.custom-slide-x-transition {
  transition-duration: 0.5s !important;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
